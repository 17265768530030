import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Splash from "../../components/splash"

export const Head = ({
  location,
  data: {
    markdownRemark: { frontmatter, excerpt },
  }, // this prop will be injected by the GraphQL query below.
}) => (
  <Seo
    title={`${frontmatter.title} | Blog`}
    description={excerpt}
    pathname={location.pathname}
    image={
      frontmatter.featuredImage?.childImageSharp?.gatsbyImageData.images
        .fallback.src
    }
  />
)

export default function BlogPostTemplate({
  data: {
    markdownRemark: { frontmatter, html },
  }, // this prop will be injected by the GraphQL query below.
}) {
  return (
    <Layout
      splash={
        <Splash image={frontmatter.featuredImage}>{frontmatter.title}</Splash>
      }
    >
      <p>
        <em>{frontmatter.date}</em>
      </p>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt(pruneLength: 250)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 1920)
          }
        }
      }
    }
  }
`

import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as styles from "./splash.module.scss"

const Splash = ({ children, image }) => {
  let img = getImage(image?.childImageSharp?.gatsbyImageData)

  return (
    <div className={styles.splash}>
      <div className={styles.splashImage}>
        <GatsbyImage image={img} />
      </div>
      <h1 className={styles.content}>{children}</h1>
    </div>
  )
}

Splash.propTypes = {
  children: PropTypes.any,
  image: PropTypes.any,
}

export default Splash
